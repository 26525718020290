/* eslint-disable */

import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import createStoreContext from '../createStoreContext';
import { API_ROUTES } from '../../variables/api';
import { ApiResponse } from '../../variables/generalTypes';
import { UserData } from 'amazon-cognito-identity-js';
import i18n from '../../helpers/i18n';

export interface Kiosk {
  _id: string;
  label: string;
  status: string;
  mft: string;
  mod: string;
  type: string;
  bn: string;
  tot: number;
  imei: number;
  apn: string;
  md5: string;
  sessionStart: string;
  __v: number;
  act: number;
  sessionEnd: string;
}

export type KioskEnvType = 'development' | 'production';

export const [KioskStoreProvider, useKioskStore] = createStoreContext(
  class AsnStore {
    private readonly apiKey: string | undefined = process.env.REACT_APP_API_ENDPOINT;
    kiosk: Kiosk | null | undefined = null;

    public constructor() {
      // NOTE: don't add anything else here  unless you know what you are doing
      // as the constructor will be executed twice due to `StrictMode`
      makeAutoObservable(this);
    }

    getKioskByDsn = async (dsn: string, env: KioskEnvType = 'development') => {
      this.kiosk = null;

      const res = await axios.post<ApiResponse<Kiosk>>(this.apiKey + API_ROUTES.GET_KIOSK_BY_DSN, {
        dsn,
        env,
      });

      if (res.data.status === 'success') {
        this.kiosk = res.data.data;
        return res.data.data;
      } else if (res.data.status === 'error' || res.data.status === 'fail') {
        throw new Error(res.data.message || (i18n.t('somethingWentWrong') as string));
      } else {
        throw new Error(i18n.t('unhandledScenario') as string);
      }
    };

    attachKioskToAsn = async (dsn: string, asn: string, userData: UserData) => {
      const sub = userData.Username;
      const email = userData.UserAttributes.find((attr) => attr.Name === 'email')?.Value;
      const team = email?.split('@')[email?.split('@').length - 1];
      if (!sub || !email || !team) throw new Error(i18n.t('paramsNotDefined') as string);

      const res = await axios.post<ApiResponse<Kiosk>>(
        this.apiKey + API_ROUTES.ATTACH_KIOSK_TO_ASN,
        {
          dsn,
          asn,
          email,
          sub,
          team,
        },
      );

      if (res.data.status === 'success') {
        this.kiosk = res.data.data;
        return res.data.data;
      } else if (res.data.status === 'error' || res.data.status === 'fail') {
        throw new Error(res.data.message || (i18n.t('somethingWentWrong') as string));
      } else {
        throw new Error(i18n.t('unhandledScenario') as string);
      }
    };
  },
);

export type GlobalStore = ReturnType<typeof useKioskStore>;
