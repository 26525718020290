import React from 'react';
import AxiosContext from './axiosContext';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { GENERAL } from '../../variables/general';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from '../../stores/AuthStore/AuthStore';

export const setAuthToken = (token) => {
  if (token) {
    localStorage.setItem('token', token);
  } else {
    localStorage.removeItem('token');
  }
};

const AxiosState = observer((props) => {
  const history = useHistory();
  const authStore = useAuthStore();
  const toast = useToast();

  // Request interceptor for API calls
  axios.interceptors.request.use(
    async (config) => {
      // Nothing much to do for request handler at the moment
      const token = localStorage.getItem('token');
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  // Response interceptor for API calls
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          await authStore.refreshToken();
          return axios(originalRequest);
        } catch (error) {
          console.log(error);
          toast({
            title: 'Unauthorized',
            status: 'error',
            duration: GENERAL.TOAST_DURATION,
            isClosable: true,
          });
          authStore.logout();
          history.push('/auth/signin');
          return Promise.reject(error);
        }
      } else {
        return Promise.reject(error);
      }
    },
  );

  return <AxiosContext.Provider value={{ axios }}>{props.children}</AxiosContext.Provider>;
});

export default AxiosState;
