import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  TableContainer,
  Tooltip,
  IconButton,
  CircularProgress,
} from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import React, { useEffect, useMemo } from 'react';
import { useTable, usePagination, Row } from 'react-table';
import { observer } from 'mobx-react-lite';
import { TableColumn } from '../../variables/generalTypes';
import { useTranslation } from 'react-i18next';

export interface PaginatedTableProps {
  columnsData: Array<TableColumn>;
  tableData: Array<any>;
  onPageParamsChange: (pageIndex: number, pageSize: number) => void;
  pageSize: number;
  pageCount: number;
  loading: boolean;
  renderRow: (
    columnsData: Array<TableColumn>,
    row: Row<any>,
    textColor: string,
  ) => Array<JSX.Element>;
}

const PaginatedTable = observer(
  ({
    columnsData,
    tableData,
    onPageParamsChange,
    pageSize: controlledPageSize,
    pageCount: controlledPageCount,
    loading,
    renderRow,
  }: PaginatedTableProps) => {
    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);
    const pageSizeMemo = useMemo(() => controlledPageSize, [controlledPageSize]);
    const pageCountMemo = useMemo(() => controlledPageCount, [controlledPageCount]);
    const { t } = useTranslation();

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      state: { pageIndex },
      nextPage,
      previousPage,
      canNextPage,
      canPreviousPage,
      pageCount,
      gotoPage,
      pageOptions,
    } = useTable<any>(
      {
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: pageSizeMemo },
        pageCount: pageCountMemo,
        manualPagination: true,
      },
      usePagination,
    );

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

    useEffect(() => {
      onPageParamsChange(pageIndex, pageSizeMemo);
    }, [pageIndex, pageSizeMemo]);

    if (loading) {
      return (
        <Flex flex={1} justifyContent="center" alignItems="center">
          <CircularProgress
            size="36px"
            thickness="4px"
            color="brandPrimary.500"
            isIndeterminate
          ></CircularProgress>
        </Flex>
      );
    }

    return (
      <>
        <TableContainer>
          <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps()}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.400"
                      >
                        {column.render('Header')}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {renderRow(columnsData, row, textColor)}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
        <Flex justifyContent="space-between" mt="2rem" alignItems="center">
          <Flex>
            <Tooltip label={t('firstPage')}>
              <IconButton
                borderRadius="50%"
                aria-label={t('firstPage')}
                onClick={() => gotoPage(0)}
                isDisabled={!canPreviousPage}
                icon={<ArrowLeftIcon h={3} w={3} />}
                mr={4}
              />
            </Tooltip>
            <Tooltip label={t('previousPage')}>
              <IconButton
                borderRadius="50%"
                aria-label={t('previousPage')}
                onClick={previousPage}
                isDisabled={!canPreviousPage}
                icon={<ChevronLeftIcon h={6} w={6} />}
              />
            </Tooltip>
          </Flex>

          <Flex alignItems="center">
            <Text flexShrink={0} textAlign="center">
              {t('page')}{' '}
              <Text fontWeight="bold" as="span">
                {pageIndex + 1}
              </Text>{' '}
              /{' '}
              <Text fontWeight="bold" as="span">
                {pageOptions.length}
              </Text>
            </Text>
          </Flex>

          <Flex>
            <Tooltip label={t('nextPage')}>
              <IconButton
                aria-label={t('nextPage')}
                onClick={nextPage}
                isDisabled={!canNextPage}
                icon={<ChevronRightIcon h={6} w={6} />}
                borderRadius="50%"
              />
            </Tooltip>
            <Tooltip label={t('lastPage')}>
              <IconButton
                aria-label={t('lastPage')}
                onClick={() => gotoPage(pageCount - 1)}
                isDisabled={!canNextPage}
                icon={<ArrowRightIcon h={3} w={3} />}
                ml={4}
                borderRadius="50%"
              />
            </Tooltip>
          </Flex>
        </Flex>
      </>
    );
  },
);

export default PaginatedTable;
