import { TableColumn } from '../../../../variables/generalTypes';

export const columnsDataAsnListTable: Array<TableColumn> = [
  {
    Header: 'Id',
    accessor: 'id',
    i18nAccessor: 'id',
  },
  {
    Header: 'Status',
    accessor: 'status',
    i18nAccessor: 'status',
  },
  {
    Header: 'Attached Kiosk',
    accessor: 'dsn',
    i18nAccessor: 'attachedKiosk',
  },
  {
    Header: 'Assigned Partner',
    accessor: 'manager',
    i18nAccessor: 'assignedPartner',
  },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    i18nAccessor: 'createdAt',
  },
  {
    Header: 'Created By',
    accessor: 'createdBy',
    i18nAccessor: 'createdBy',
  },
];
