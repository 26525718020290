// Chakra Imports
import { Button, Icon, useColorMode } from '@chakra-ui/react';
// Custom Icons
import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import React from 'react';
import { globalStyles } from '../../theme/styles';

export default function FixedPlugin(props) {
  const { ...rest } = props;
  const { colorMode, toggleColorMode } = useColorMode();

  let bgButton = `linear-gradient(135deg, ${globalStyles.colors.brandPrimary[500]} 0%, ${globalStyles.colors.brandPrimary[200]} 100%)`;

  return (
    <Button
      {...rest}
      h='60px'
      w='60px'
      bg={bgButton}
      zIndex='99'
      position='fixed'
      variant='no-effects'
      right='35px'
      bottom='30px'
      border='1px solid'
      borderColor={globalStyles.colors.brandPrimary[500]}
      borderRadius='50px'
      onClick={toggleColorMode}
      display='flex'
      p='0px'
      align='center'
      justify='center'
    >
      <Icon h='24px' w='24px' color='white' as={colorMode === 'light' ? IoMdMoon : IoMdSunny} />
    </Button>
  );
}
