/* eslint-disable */

import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  UserData,
} from 'amazon-cognito-identity-js';
import { makeAutoObservable } from 'mobx';
import { setAuthToken } from '../../contexts/axios/AxiosState';
import i18n from '../../helpers/i18n';
import createStoreContext from '../createStoreContext';

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USER_POOL_ID as string,
  ClientId: process.env.REACT_APP_CLIENT_ID as string,
});

export const [AuthStoreProvider, useAuthStore] = createStoreContext(
  class AuthStore {
    token = localStorage.getItem('token');
    isAuthenticated = false;
    isVerified = false; // Has the email been verified?
    user: CognitoUser | null = null; // cognito user
    userData: UserData | undefined; // From cognitoUser.getUserData
    error: Error | null = null;

    public constructor() {
      // NOTE: don't add anything else here  unless you know what you are doing
      // as the constructor will be executed twice due to `StrictMode`
      makeAutoObservable(this);
    }

    // Load User
    loadUser(cognitoUser: CognitoUser): Promise<UserData | undefined> {
      return new Promise((resolve, reject) => {
        try {
          cognitoUser.getUserData((error: Error | undefined, userData: UserData | undefined) => {
            if (error) {
              reject(error);
            }
            resolve(userData);
          });
        } catch (error) {
          reject(error);
        }
      });
    }

    // Forgot password
    forgotPassword(email: string) {
      return new Promise((resolve, reject) => {
        const userData = {
          Username: email,
          Pool: userPool,
        };
        const cognitoUser = new CognitoUser(userData);

        cognitoUser.forgotPassword({
          onSuccess: (data: any) => {
            // successfully initiated reset password request
            this.user = cognitoUser;
            resolve(data);
          },
          onFailure: (error: any) => {
            this.error = error;
            this.user = cognitoUser;
            reject(error);
          },
        });
      });
    }

    verifyForgotPassword(cognitoUser: CognitoUser, values: any) {
      return new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(values.code, values.password, {
          onSuccess: () => {
            this.isVerified = true;
            resolve(true);
          },
          onFailure: (error) => {
            this.isVerified = false;
            this.user = cognitoUser;
            this.error = error;
            reject(error);
          },
        });
      });
    }

    reset() {
      this.token = null;
      this.isAuthenticated = false;
      this.isVerified = false;
      this.error = null;
    }

    // Register User
    register(formData: any) {
      return new Promise((resolve, reject) => {
        try {
          const attributeList = [];
          const dataEmail = {
            Name: 'email',
            Value: formData.email,
          };
          const attributeEmail = new CognitoUserAttribute(dataEmail);
          attributeList.push(attributeEmail);

          userPool.signUp(
            formData.email,
            formData.password,
            attributeList,
            [],
            (error, result: any) => {
              if (error) {
                this.reset();
                this.error = error;
                reject(error);
              }
              const cognitoUser = result.user;
              this.user = cognitoUser;
              resolve(cognitoUser);
            },
          );
        } catch (error: any) {
          this.reset();
          this.error = error;
          reject(error);
        }
      });
    }

    verifyUser(otp: string, email: string) {
      return new Promise((resolve, reject) => {
        const userData = {
          Username: email,
          Pool: userPool,
        };
        const cognitoUser = new CognitoUser(userData);
        this.user = cognitoUser;

        try {
          cognitoUser.confirmRegistration(otp, true, (error, result) => {
            if (error) {
              this.isVerified = false;
              this.error = error;
              reject(error);
            }
            this.isVerified = true;
            resolve(result);
          });
        } catch (error: any) {
          this.error = error;
          this.isVerified = false;
          reject(error);
        }
      });
    }

    resendConfirmationCode(cognitoUser: CognitoUser) {
      return new Promise((resolve, reject) => {
        try {
          cognitoUser.resendConfirmationCode((error, result) => {
            if (error) {
              this.user = cognitoUser;
              this.error = error;
              reject(error);
            }

            resolve(result);
          });
        } catch (error: any) {
          this.user = cognitoUser;
          this.error = error;
          reject(error);
        }
      });
    }

    login(formData: any) {
      return new Promise((resolve, reject) => {
        try {
          const authenticationData = {
            Username: formData.email,
            Password: formData.password,
          };

          const authenticationDetails = new AuthenticationDetails(authenticationData);

          const userData = {
            Username: formData.email,
            Pool: userPool,
          };

          const cognitoUser = new CognitoUser(userData);

          cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
              const accessToken = result.getAccessToken().getJwtToken();

              setAuthToken(accessToken);

              this.loadUser(cognitoUser)
                .then((userData) => {
                  this.token = localStorage.getItem('token');
                  this.isAuthenticated = true;
                  this.isVerified = true;
                  this.user = cognitoUser;
                  this.userData = userData;
                  this.error = null;
                  resolve(userData);
                })
                .catch((error: any) => {
                  this.reset();
                  this.error = error;
                  reject(error);
                });
            },

            onFailure: (error) => {
              this.reset();
              this.user = cognitoUser;
              reject(error);
            },
          });
        } catch (error: any) {
          this.reset();
          this.error = error;
          reject(error);
        }
      });
    }

    autoLogin() {
      return new Promise((resolve, reject) => {
        try {
          const cognitoUser = userPool.getCurrentUser();

          if (cognitoUser != null) {
            cognitoUser.getSession(async (error: any, session: any) => {
              if (error) {
                this.reset();
                this.error = error;
                reject(error);
              }
              if (session?.isValid()) {
                this.loadUser(cognitoUser)
                  .then((userData: any) => {
                    setAuthToken(session.getAccessToken().getJwtToken());

                    this.user = cognitoUser;
                    this.isAuthenticated = true;
                    this.isVerified = true;
                    this.error = null;
                    this.token = localStorage.getItem('token');
                    this.userData = userData;
                    resolve(userData);
                  })
                  .catch((error) => {
                    this.reset();
                    this.error = error;
                    reject(error);
                  });
              } else {
                this.reset();
                this.error = new Error(i18n.t('notAuthorized') as string);
                reject(session);
              }
            });
          } else {
            const error = new Error('cognitoUser null');
            this.reset();
            this.error = error;
            reject(error);
          }
        } catch (error: any) {
          this.reset();
          this.error = error;
          reject(error);
        }
      });
    }

    refreshToken() {
      return new Promise((resolve, reject) => {
        try {
          const cognitoUser = userPool.getCurrentUser();
          cognitoUser?.getSession((error: any, session: any) => {
            if (error) {
              this.reset();
              this.error = error;
              reject(error);
            } else {
              const refresh_token = session.getRefreshToken();

              cognitoUser.refreshSession(refresh_token, (error, session) => {
                if (error) {
                  this.reset();
                  this.error = error;
                  reject(error);
                } else {
                  const newToken = session.getAccessToken().getJwtToken();
                  setAuthToken(newToken);
                  (this.token = localStorage.getItem('token')), (this.isAuthenticated = true);
                  this.isVerified = true;
                  this.error = null;
                  resolve(newToken);
                }
              });
            }
          });
        } catch (error: any) {
          this.reset();
          this.error = error;
          reject(error);
        }
      });
    }

    // Logout
    logout() {
      const cognitoUser = userPool.getCurrentUser();
      cognitoUser?.signOut();
      this.reset();
    }
  },
);

export type GlobalStore = ReturnType<typeof useAuthStore>;
