import { Icon } from '@chakra-ui/react';
import { MdOutlineImportantDevices, MdPeople } from 'react-icons/md';
import Asns from '../views/admin/Asns';
import Partners from '../views/admin/Partners';
import i18n from '../helpers/i18n';
import { FunctionComponent, ReactElement } from 'react';

export interface Route {
  name: string;
  layout: string;
  path: string;
  icon: ReactElement;
  component: FunctionComponent;
  i18nAccessor?: string;
}

const routes: Array<Route> = [
  {
    name: 'ASNs',
    layout: '/admin',
    path: '/asns',
    icon: <Icon as={MdOutlineImportantDevices} width="20px" height="20px" color="inherit" />,
    component: Asns,
  },
  {
    name: 'Partners',
    layout: '/admin',
    icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
    path: '/partners',
    component: Partners,
    i18nAccessor: 'partners',
  },
];

export default routes;
