import { mode } from '@chakra-ui/theme-tools';
export const globalStyles = {
  colors: {
    brandPrimary: {
      50: '#fff4dc',
      100: '#fde2b0',
      200: '#f9cf83',
      300: '#f6bc54',
      400: '#f3a825',
      500: '#da8f0c',
      600: '#a96f06',
      700: '#7a4f03',
      800: '#4a3000',
      900: '#1c0f00',
    },
    brandSecondary: {
      50: '#feeff2',
      100: '#ded7d9',
      200: '#c2bebf',
      300: '#a7a5a6',
      400: '#8c8c8c',
      500: '#737373',
      600: '#5a5959',
      700: '#423f40',
      800: '#2a2526',
      900: '#17080d',
    },
    red: {
      100: '#FEEFEE',
      500: '#EE5D50',
      600: '#E31A1A',
    },
    blue: {
      50: '#EFF4FB',
      500: '#3965FF',
    },
    orange: {
      100: '#FFF6DA',
      500: '#FFB547',
    },
    green: {
      100: '#E6FAF5',
      500: '#01B574',
    },
    textOnPrimary: {
      500: '#000000',
    },
    textOnSecondary: {
      500: '#ffffff',
    },
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: 'hidden',
        bg: mode('secondaryGray.300', 'navy.900')(props),
        fontFamily: 'roboto',
        letterSpacing: '-0.5px',
      },
      input: {
        color: 'gray.700',
      },
      html: {
        fontFamily: 'roboto',
      },
    }),
  },
};
