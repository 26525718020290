import React, { Fragment } from 'react';
import '../../assets/css/FullLoader.css';
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { GoPowerLogoMark } from '../icons/Icons';
import { useFullLoaderStore } from '../../stores/FullLoaderStore/FullLoaderStore';
import { observer } from 'mobx-react-lite';

const FullLoader = observer(() => {
  const fullLoaderStore = useFullLoaderStore();

  return (
    <Fragment>
      {fullLoaderStore.loading ? (
        <Fragment>
          <div className="backdrop"></div>
          <div className="full-loader-container">
            <CircularProgress size="120px" thickness="2px" color="brandPrimary.500" isIndeterminate>
              <CircularProgressLabel>
                <GoPowerLogoMark h="64px" w="auto" color="black" />
              </CircularProgressLabel>
            </CircularProgress>
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
});

export default FullLoader;
