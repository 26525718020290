import { Box, Flex, SimpleGrid, Td, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Card from '../../../components/card/Card';
import { observer } from 'mobx-react-lite';
import { usePartnerStore } from '../../../stores/PartnerStore/PartnerStore';
import { GENERAL } from '../../../variables/general';
import { columnsDataPartnerListTable } from './components/partnerTableMapping';
import { TableColumn } from '../../../variables/generalTypes';
import { Row } from 'react-table';
import ShowMoreTable from '../../../components/show-more-table/ShowMoreTable';
import { useTranslation } from 'react-i18next';

const Partners = observer(() => {
  const partnerStore = usePartnerStore();
  const toast = useToast();
  const [gettingPartnerList, setGettingPartnerList] = useState(true);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const pageSize = 20;
  const { t, i18n } = useTranslation();

  const getPartnerListData = () => {
    setGettingPartnerList(true);
    return partnerStore
      .getPartnerList(pageSize)
      .then(() => {
        setGettingPartnerList(false);
      })
      .catch((error) => {
        toast({
          title: error.message || t('somethingWentWrong'),
          status: 'error',
          duration: GENERAL.TOAST_DURATION,
          isClosable: true,
        });
        setGettingPartnerList(false);
      });
  };

  const mutateColumnsDataWithRightTranslation = (columnsData: Array<TableColumn>) => {
    return columnsData.map((column) => {
      return {
        ...column,
        Header: t(column.i18nAccessor),
      };
    });
  };

  const renderPartnerListRow = (
    columnsData: Array<TableColumn>,
    row: Row<any>,
    textColor: string,
  ) => {
    return row.cells.map((cell, index) => {
      let data: JSX.Element | null = null;
      if (cell.column.Header === columnsData[0].Header) {
        data = (
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {cell.value}
          </Text>
        );
      } else if (cell.column.Header === columnsData[1].Header) {
        data = (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {cell.value}
            </Text>
          </Flex>
        );
      } else if (cell.column.Header === columnsData[2].Header) {
        data = (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {cell.value}
            </Text>
          </Flex>
        );
      } else if (cell.column.Header === columnsData[3].Header) {
        data = (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {cell.value}
            </Text>
          </Flex>
        );
      }
      return (
        <Td
          {...cell.getCellProps()}
          key={index}
          fontSize={{ sm: '14px' }}
          minW={{ sm: 'auto', md: 'auto', lg: 'auto' }}
          borderColor="transparent"
        >
          {data}
        </Td>
      );
    });
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
        <Card direction="column" w="100%">
          <Flex px="25px" justify="space-between" mb="20px" align="center">
            <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
              {t('partners')}
            </Text>
          </Flex>
          <ShowMoreTable
            onShowMore={getPartnerListData}
            columnsData={mutateColumnsDataWithRightTranslation(columnsDataPartnerListTable)}
            tableData={partnerStore.getDataForPartnerListTable()}
            loading={gettingPartnerList}
            renderRow={renderPartnerListRow}
            paginationToken={partnerStore.paginationToken}
          />
        </Card>
      </SimpleGrid>
    </Box>
  );
});

export default Partners;
