/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _|
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  PinInput,
  PinInputField,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { EMAIL_REGEX_PATTERN } from '../../../variables/regex-expressions';
import { GENERAL } from '../../../variables/general';
import '../../../assets/css/LoginSignUp.css';
import { useFullLoaderStore } from '../../../stores/FullLoaderStore/FullLoaderStore';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from '../../../stores/AuthStore/AuthStore';
import { useTranslation } from 'react-i18next';

const SignIn = observer(() => {
  // Chakra color mode
  const textColor = useColorModeValue('textOnPrimary.500', 'white');
  const textColorSecondary = useColorModeValue('textOnPrimary.500', 'white');
  const textColorDetails = useColorModeValue('brandSecondary.700', 'brandSecondary.600');
  const textColorBrand = useColorModeValue('brandPrimary.500', 'white');
  const [show, setShow] = useState(false);
  const toast = useToast();
  const fullLoaderStore = useFullLoaderStore();
  const authStore = useAuthStore();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const otpResendTimeGap = 30; // seconds
  const [resendButtonIsDisabled, setResendButtonIsDisabled] = useState(true);
  const formValues = {
    email: '',
    password: '',
  };
  const otpNumberColor = useColorModeValue('black', 'white');
  const { t } = useTranslation();

  useEffect(() => {
    // Try auto login first from a previous session before asking the user to sing in again
    fullLoaderStore.showFullLoader();
    authStore
      .autoLogin()
      .then(() => {
        fullLoaderStore.hideFullLoader();
        toast({
          title: t('autoLoggedIn'),
          description: 'Welcome back!',
          status: 'success',
          duration: GENERAL.TOAST_DURATION,
          isClosable: true,
        });
        history.push('/');
      })
      .catch(() => {
        fullLoaderStore.hideFullLoader();
      });
  }, []);

  const handleClick = () => setShow(!show);

  const formValidate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = t('enterEmail');
    } else if (!values.email.toLowerCase().match(EMAIL_REGEX_PATTERN)) {
      errors.email = t('enterEmailInCorrectFormat');
    } else if (!values.password) {
      errors.password = t('enterPassword');
    } else {
      // Nothing to handle
    }
    return errors;
  };

  const startOtpButtonTimeout = () => {
    setResendButtonIsDisabled(true);
    setTimeout(() => {
      setResendButtonIsDisabled(false);
    }, otpResendTimeGap * 1000);
  };

  const onSubmitForm = (values, actions) => {
    actions.setSubmitting(true);
    fullLoaderStore.showFullLoader();

    authStore
      .login(values)
      .then(() => {
        toast({
          title: t('welcomeBack'),
          status: 'success',
          duration: GENERAL.TOAST_DURATION,
          isClosable: true,
        });
        fullLoaderStore.hideFullLoader();
        actions.setSubmitting(false);
        history.push('/');
      })
      .catch((error) => {
        if (error.code === 'UserNotConfirmedException') {
          // User needs verification, trigger verification flow
          resendOTP();
          onOpen();
        }
        toast({
          title: error.message || t('somethingWentWrong'),
          status: 'error',
          duration: GENERAL.TOAST_DURATION,
          isClosable: true,
        });

        fullLoaderStore.hideFullLoader();
        actions.setSubmitting(false);
      });
  };

  const resendOTP = () => {
    fullLoaderStore.showFullLoader();
    startOtpButtonTimeout();

    authStore
      .resendConfirmationCode(authStore.user)
      .then(() => {
        toast({
          title: t('otpSent'),
          status: 'success',
          duration: GENERAL.TOAST_DURATION,
          isClosable: true,
        });

        fullLoaderStore.hideFullLoader();
      })
      .catch((error) => {
        toast({
          title: error.message || t('somethingWentWrong'),
          status: 'error',
          duration: GENERAL.TOAST_DURATION,
          isClosable: true,
        });
        fullLoaderStore.hideFullLoader();
      });
  };

  const otpComplete = (otp) => {
    fullLoaderStore.showFullLoader();

    authStore
      .verifyUser(otp, authStore.user.username)
      .then(() => {
        toast({
          title: t('emailVerifiedPleaseLogin'),
          status: 'success',
          duration: GENERAL.TOAST_DURATION,
          isClosable: true,
        });
        onClose(); // close modal

        fullLoaderStore.hideFullLoader();
        history.push('/auth/signin');
      })
      .catch((error) => {
        toast({
          title: error.message || t('somethingWentWrong'),
          status: 'error',
          duration: GENERAL.TOAST_DURATION,
          isClosable: true,
        });
        fullLoaderStore.hideFullLoader();
      });
  };

  return (
    <Flex
      maxW={{ base: '100%', md: 'max-content' }}
      w="100%"
      mx={{ base: 'auto', lg: '0px' }}
      me="auto"
      h="100%"
      alignItems="start"
      justifyContent="center"
      mb={{ base: '30px', md: '60px' }}
      px={{ base: '25px', md: '0px' }}
      mt={{ base: '40px', md: '14vh' }}
      flexDirection="column"
    >
      <Box me="auto">
        <Heading color={textColor} fontSize="36px" mb="10px">
          {t('goPowerSupplierPlatform')}
        </Heading>
        <Text mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">
          {t('enterToSignIn')}
        </Text>
      </Box>
      <Flex
        zIndex="2"
        direction="column"
        w={{ base: '100%', md: '420px' }}
        maxW="100%"
        background="transparent"
        borderRadius="15px"
        mx={{ base: 'auto', lg: 'unset' }}
        me="auto"
        mb={{ base: '20px', md: 'auto' }}
      >
        <Formik initialValues={formValues} onSubmit={onSubmitForm} validate={formValidate}>
          {({ handleSubmit, isSubmitting, handleChange, handleBlur }) => (
            <Form onSubmit={handleSubmit}>
              <Field name="email">
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.email && form.touched.email} isRequired>
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      {t('email')}
                    </FormLabel>
                    <Input
                      {...field}
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      type="email"
                      placeholder="johndoe@xyz.com"
                      mb="24px"
                      fontWeight="500"
                      size="lg"
                    />
                    <FormErrorMessage className="login-form-error-message">
                      {form.errors.email}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="password">
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.password && form.touched.password} isRequired>
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      {t('password')}
                    </FormLabel>
                    <InputGroup size="md">
                      <Input
                        {...field}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="password"
                        fontSize="sm"
                        placeholder="Please enter password"
                        mb="24px"
                        size="lg"
                        type={show ? 'text' : 'password'}
                        variant="auth"
                      />
                      <InputRightElement display="flex" alignItems="center" mt="4px">
                        <Icon
                          color={textColorSecondary}
                          _hover={{ cursor: 'pointer' }}
                          as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                          onClick={handleClick}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage className="login-form-error-message">
                      {form.errors.password}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Flex justifyContent="space-between" align="center" mb="24px">
                <NavLink to="/auth/forgot-password">
                  <Text color={textColorBrand} fontSize="sm" w="124px" fontWeight="500">
                    {t('forgotPassword')}
                  </Text>
                </NavLink>
              </Flex>
              <Button
                disabled={isSubmitting}
                fontSize="sm"
                variant="brandPrimary"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                type="submit"
              >
                {t('signIn')}
              </Button>
            </Form>
          )}
        </Formik>

        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent width={{ sm: '80vw', md: 'auto' }}>
            <ModalHeader>{t('emailVerification')}</ModalHeader>
            <ModalBody>
              <Text mb="32px">{t('confirmOtp')}</Text>
              <HStack>
                <PinInput otp size="lg" onComplete={otpComplete}>
                  <PinInputField color={otpNumberColor} />
                  <PinInputField color={otpNumberColor} />
                  <PinInputField color={otpNumberColor} />
                  <PinInputField color={otpNumberColor} />
                  <PinInputField color={otpNumberColor} />
                  <PinInputField color={otpNumberColor} />
                </PinInput>
              </HStack>
            </ModalBody>
            <ModalFooter>
              <Button onClick={resendOTP} disabled={resendButtonIsDisabled} variant="action">
                {t('resendOtp')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="start"
          maxW="100%"
          mt="0px"
        >
          <Text color={textColorDetails} fontWeight="400" fontSize="14px">
            {t('notRegisteredYet')}
            <NavLink to="/auth/signup">
              <Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
                {t('createAccount')}
              </Text>
            </NavLink>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
});

export default SignIn;
