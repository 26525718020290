import { Box, Flex, Icon, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import Footer from '../../components/footer/FooterAuth';
import illustrationBackground from '../../assets/img/auth/IMG_1178.jpg';
import PropTypes from 'prop-types';
import { GENERAL } from '../../variables/general';
import SignIn from '../../views/auth/sign-in/SignIn';
import SignUp from '../../views/auth/sign-up/SignUp';
import ForgotPassword from '../../views/auth/forgot-password/ForgotPassword';
import { useTranslation } from 'react-i18next';

const AuthIllustration = ({ formType }) => {
  const { t } = useTranslation();

  // Chakra color modes
  return (
    <Flex position="relative" h="max-content">
      <Box
        display={{ base: 'none', md: 'block' }}
        minH="90vh"
        w={{ lg: '50vw', '2xl': '44vw' }}
        h={{
          sm: 'initial',
          md: 'unset',
          lg: '90vh',
          xl: '90vh',
        }}
        position="absolute"
        right="0px"
      >
        <Flex
          bg={`url(/IMG_1178.jpg)`}
          justify="center"
          align="end"
          w="100%"
          h="100%"
          bgSize="cover"
          bgPosition="50%"
          position="absolute"
          borderBottomLeftRadius={{ lg: '120px', xl: '200px' }}
        ></Flex>
      </Box>
      <Flex
        w="100%"
        maxW={{ md: '66%', lg: '1313px' }}
        mx="auto"
        pt="0"
        px={{ lg: '30px', xl: '0px' }}
        ps={{ xl: '70px' }}
        justifyContent="start"
        direction="column"
      >
        <Link href={GENERAL.GOPOWER_LANDING_PAGE} width={'fit-content'} mt="40px">
          <Flex
            align="center"
            ps={{ base: '25px', lg: '0px' }}
            pt={{ lg: '0px', xl: '0px' }}
            w="fit-content"
          >
            <Icon as={FaChevronLeft} me="12px" h="13px" w="8px" color="secondaryGray.600" />
            <Text ms="0px" fontSize="sm" color="secondaryGray.600">
              {t('goToLandingPage')}
            </Text>
          </Flex>
        </Link>
        {formType === 'signin' ? (
          <SignIn />
        ) : formType === 'signup' ? (
          <SignUp />
        ) : formType === 'forgotPassword' ? (
          <ForgotPassword />
        ) : null}
        <Footer />
      </Flex>
    </Flex>
  );
};

AuthIllustration.propTypes = {
  formType: PropTypes.string.isRequired,
};

export default AuthIllustration;
