/**
 * Standard - JSend - https://github.com/omniti-labs/jsend
 *
 * - JSON object ⇒ `{result: "", msg: ""}`
    - `status`
        - `success`: function runs successfully
        - `fail`: There was a problem with the data submitted, or some pre-condition of the API call wasn't satisfied, known error.
        - `error`: function runs into an error or exception
    - `data`
        - `result="success"`: function return payload
        - `result="fail"`: function failure payload
    - `message`
        - `result=”error”`: error message
 */

export const API_ROUTES = {
  GENERATE_NEW_ASN: '/v1/asset/create',
  LIST_ASN: '/v1/asset/list',
  GET_PARTNER_BY_EMAIL: '/v1/partner/get-by-email',
  GET_KIOSK_BY_DSN: '/v1/box/find-by-dsn',
  ATTACH_KIOSK_TO_ASN: '/v1/asset/attach-box',
  ASSIGN_PARTNER_TO_ASN: '/v1/asset/assign-partner',
  GET_PARTNER_LIST: '/v1/partner/list',
};

export const AXIOS_CONFIG = {
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' },
};
