import React from 'react';

// Chakra imports
import { Flex } from '@chakra-ui/react';
import { useColorMode } from '@chakra-ui/react';

// Custom components
import { GoPowerLogo } from '../../../components/icons/Icons';
import { HSeparator } from '../../../components/separator/Separator';

const SidebarBrand = () => {
  const { colorMode } = useColorMode();
  return (
    <Flex align="center" direction="column">
      <GoPowerLogo
        h="64px"
        w="100%"
        mb="32px"
        color={colorMode === 'light' ? 'brandSecondary.900' : 'brandSecondary.50'}
      />
      <HSeparator mb="20px" />
    </Flex>
  );
};

export default SidebarBrand;
