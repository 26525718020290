import { TableColumn } from '../../../../variables/generalTypes';
import i18n from '../../../../helpers/i18n';

export const columnsDataPartnerListTable: Array<TableColumn> = [
  {
    Header: 'Email',
    accessor: 'email',
    i18nAccessor: 'email',
  },
  {
    Header: 'Enabled',
    accessor: 'enabled',
    i18nAccessor: 'enabled',
  },
  {
    Header: 'Created Date',
    accessor: 'createdDate',
    i18nAccessor: 'createdDate',
  },
  {
    Header: 'Last Modified Date',
    accessor: 'lastModifiedDate',
    i18nAccessor: 'lastModifiedDate',
  },
];
