// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
  useToast,
  Box,
} from '@chakra-ui/react';
import { SidebarResponsive } from '../../components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import React from 'react';
import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import routes from '../../variables/routes';
import { GENERAL } from '../../variables/general';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from '../../stores/AuthStore/AuthStore';
import { useTranslation } from 'react-i18next';

const HeaderLinks = observer((props) => {
  const { secondary } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode
  const navbarIcon = useColorModeValue('brandSecondary.400', 'white');
  let menuBg = useColorModeValue('white', 'brandSecondary.700');
  const textColor = useColorModeValue('textOnPrimary.500', 'textOnSecondary.500');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(0,0,0,0.04)',
  );

  const authStore = useAuthStore();
  const toast = useToast();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const userNameCharacters = 16;
  const userName =
    authStore.userData.UserAttributes.find(
      (attribute) => attribute.Name === 'email',
    ).Value.substring(0, userNameCharacters) + '...';

  const logUserOut = () => {
    toast({
      title: t('loggedOut'),
      status: 'success',
      duration: GENERAL.TOAST_DURATION,
      isClosable: true,
    });

    authStore.logout(authStore.user);
    history.push('/auth/signin');
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
    >
      <Box w="100%" p="0 8px">
        <a href={`mailto:${GENERAL.CUSTOMER_SUPPORT_EMAIL}`} rel="noreferrer" target="_blank">
          {t('contactSupport')}
        </a>
      </Box>
      <Button
        variant="no-hover"
        bg="transparent"
        p="0px"
        m="0 8px"
        minW="unset"
        minH="unset"
        h="18px"
        w="max-content"
        onClick={() => changeLanguage(i18n.language === 'en' ? 'zh_CN' : 'en')}
      >
        <Text>{i18n.language === 'en' ? 'English' : '汉语'}</Text>
      </Button>
      <SidebarResponsive routes={routes} />
      <Button
        variant="no-hover"
        bg="transparent"
        p="0px"
        minW="unset"
        minH="unset"
        h="18px"
        w="max-content"
        onClick={toggleColorMode}
      >
        <Icon
          me="10px"
          h="18px"
          w="18px"
          color={navbarIcon}
          as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
        />
      </Button>
      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: 'pointer' }}
            color="white"
            name={userName}
            bg="brandPrimary.500"
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; {t('hello')}, {userName}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              color="red.400"
              borderRadius="8px"
              px="14px"
              onClick={logUserOut}
            >
              <Text fontSize="sm">{t('logOut')}</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
});

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};

export default HeaderLinks;
