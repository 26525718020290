import { mode } from '@chakra-ui/theme-tools';
export const buttonStyles = {
  components: {
    Button: {
      baseStyle: {
        borderRadius: '16px',
        boxShadow: '45px 76px 113px 7px rgba(112, 144, 176, 0.08)',
        transition: '.25s all ease',
        boxSizing: 'border-box',
        _focus: {
          boxShadow: 'none',
        },
        _active: {
          boxShadow: 'none',
        },
      },
      variants: {
        outline: () => ({
          borderRadius: '16px',
        }),
        brandPrimary: (props) => ({
          bg: mode('brandPrimary.500', 'brandPrimary.400')(props),
          color: 'white',
          _focus: {
            bg: mode('brandPrimary.600', 'brandPrimary.500')(props),
          },
          _active: {
            bg: mode('brandPrimary.700', 'brandPrimary.600')(props),
          },
          _hover: {
            bg: mode('brandPrimary.600', 'brandPrimary.500')(props),
          },
        }),
        action: (props) => ({
          fontWeight: '500',
          borderRadius: '50px',
          bg: mode('brandPrimary.500', 'brandPrimary.400')(props),
          color: mode('white', 'white')(props),
          _focus: {
            bg: mode('brandPrimary.600', 'brandPrimary.500')(props),
          },
          _active: { bg: mode('brandPrimary.700', 'brandPrimary.600')(props) },
          _hover: {
            bg: mode('brandPrimary.600', 'brandPrimary.500')(props),
          },
        }),
      },
    },
  },
};
