import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Td,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import Card from '../../../components/card/Card';
import { observer } from 'mobx-react-lite';
import { useAsnStore } from '../../../stores/AsnStore/AsnStore';
import { GENERAL } from '../../../variables/general';
import { useAuthStore } from '../../../stores/AuthStore/AuthStore';
import { TableColumn } from '../../../variables/generalTypes';
import { Row } from 'react-table';
import PaginatedTable from '../../../components/paginated-table/PaginatedTable';
import { columnsDataAsnListTable } from './components/asnTableMapping';
import AttachKioskModal from './components/AttachKioskModal';
import AssignPartnerModal from './components/AssignPartnerModal';
import { useTranslation } from 'react-i18next';

const Asns = observer(() => {
  const [gettingAsn, setGettingAsn] = useState(false);
  const [gettingAsnList, setGettingAsnList] = useState(true);
  const asnStore = useAsnStore();
  const authStore = useAuthStore();
  const toast = useToast();
  const { t, i18n } = useTranslation();
  const pageSize = 20;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const {
    isOpen: isAttachKioskModalOpen,
    onOpen: onAttachKioskModalOpen,
    onClose: onAttachKioskModalClose,
  } = useDisclosure();
  const {
    isOpen: isAssignPartnerModalOpen,
    onOpen: onAssignPartnerModalOpen,
    onClose: onAssignPartnerModalClose,
  } = useDisclosure();
  const clickedAsn = useRef('');
  const [isMounted, setIsMounted] = useState(false);

  const generateNewAsn = () => {
    if (!authStore.userData) return;

    setGettingAsn(true);
    asnStore
      .generateNewAsn(authStore.userData)
      .then((res) => {
        toast({
          title: `${t('newAsnGenerated')} - ${res?._id}`,
          status: 'success',
          duration: null,
          isClosable: true,
        });
        setGettingAsn(false);
      })
      .catch((error) => {
        toast({
          title: error.message || t('somethingWentWrong'),
          status: 'error',
          duration: GENERAL.TOAST_DURATION,
          isClosable: true,
        });
        setGettingAsn(false);
      });
  };

  const getAsnListData = (pageIndex: number, pageSize: number) => {
    if (!authStore.userData) return;

    setGettingAsnList(true);
    asnStore
      .getAsnList(authStore.userData, pageIndex, pageSize)
      .then(() => {
        setGettingAsnList(false);
      })
      .catch((error) => {
        toast({
          title: error.message || t('somethingWentWrong'),
          status: 'error',
          duration: GENERAL.TOAST_DURATION,
          isClosable: true,
        });
        setGettingAsnList(false);
      });
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!isMounted || isAttachKioskModalOpen || isAssignPartnerModalOpen || gettingAsn) return;
    getAsnListData(0, pageSize); // Start from page 0 again
  }, [isAttachKioskModalOpen, isAssignPartnerModalOpen, gettingAsn]);

  const mutateColumnsDataWithRightTranslation = (columnsData: Array<TableColumn>) => {
    return columnsData.map((column) => {
      return {
        ...column,
        Header: t(column.i18nAccessor),
      };
    });
  };

  const renderAsnListRow = (columnsData: Array<TableColumn>, row: Row<any>, textColor: string) => {
    return row.cells.map((cell, index) => {
      let data: JSX.Element | null = null;
      if (cell.column.Header === columnsData[0].Header) {
        data = (
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {cell.value}
          </Text>
        );
      } else if (cell.column.Header === columnsData[1].Header) {
        data = (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {cell.value}
            </Text>
          </Flex>
        );
      } else if (cell.column.Header === columnsData[2].Header) {
        data = (
          <Flex
            w="max-content"
            display="flex"
            flexDirection="row"
            alignItems="center"
            color={textColor}
            justifyContent="center"
          >
            {cell.value ?? (
              <Button
                onClick={() => {
                  clickedAsn.current = row.cells[0].value;
                  onAttachKioskModalOpen();
                }}
                fontSize="sm"
                variant="brandPrimary"
                fontWeight="500"
                width={{ md: 'auto', sm: '100%' }}
                mb="24px"
              >
                {t('attach')} Kiosk
              </Button>
            )}
          </Flex>
        );
      } else if (cell.column.Header === columnsData[3].Header) {
        let toRender: JSX.Element = <></>;
        if (cell.value?.email) {
          toRender = (
            <Text fontSize="sm" fontWeight="700" color={textColor}>
              {cell.value.email}
            </Text>
          );
        } else if (row.cells[index - 1].value) {
          // Kiosk assigned
          toRender = (
            <Button
              onClick={() => {
                clickedAsn.current = row.cells[0].value;
                onAssignPartnerModalOpen();
              }}
              fontSize="sm"
              variant="brandPrimary"
              fontWeight="500"
              width={{ md: 'auto', sm: '100%' }}
              mb="24px"
            >
              {t('assignPartner')}
            </Button>
          );
        } else {
          toRender = (
            <Text fontSize="sm" fontWeight="700" color={textColor}>
              {t('assignAsnFirst')}
            </Text>
          );
        }
        data = (
          <Flex
            w="max-content"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            color={textColor}
          >
            {toRender}
          </Flex>
        );
      } else if (cell.column.Header === columnsData[4].Header) {
        data = (
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {cell.value}
          </Text>
        );
      } else if (cell.column.Header === columnsData[5].Header) {
        data = (
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {cell.value}
          </Text>
        );
      }
      return (
        <Td
          {...cell.getCellProps()}
          key={index}
          fontSize={{ sm: '14px' }}
          minW={{ sm: 'auto', md: 'auto', lg: 'auto' }}
          borderColor="transparent"
        >
          {data}
        </Td>
      );
    });
  };

  return (
    <>
      <AttachKioskModal
        asn={clickedAsn.current}
        isOpen={isAttachKioskModalOpen}
        onClose={onAttachKioskModalClose}
        onOpen={onAttachKioskModalOpen}
      />
      <AssignPartnerModal
        asn={clickedAsn.current}
        isOpen={isAssignPartnerModalOpen}
        onClose={onAssignPartnerModalClose}
        onOpen={onAssignPartnerModalOpen}
      />
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
          <Card display="flex" justifyContent="center" alignItems="center">
            <Box w="100%">
              <Button
                fontSize="sm"
                variant="brandPrimary"
                fontWeight="500"
                width={{ md: 'auto', sm: '100%' }}
                mb="24px"
                onClick={generateNewAsn}
                isLoading={gettingAsn}
              >
                {t('generateNewAsn')}
              </Button>
            </Box>
            <Card direction="column" w="100%">
              <Flex px="25px" justify="space-between" mb="20px" align="center">
                <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
                  {t('yourASNs')}
                </Text>
              </Flex>
              <PaginatedTable
                onPageParamsChange={(pageIndex, pageSize) => getAsnListData(pageIndex, pageSize)}
                columnsData={mutateColumnsDataWithRightTranslation(columnsDataAsnListTable)}
                tableData={asnStore.getDataForAsnListTable()}
                loading={gettingAsnList}
                pageSize={pageSize}
                pageCount={asnStore.asnListPageCount}
                renderRow={renderAsnListRow}
              />
            </Card>
          </Card>
        </SimpleGrid>
      </Box>
    </>
  );
});

export default Asns;
