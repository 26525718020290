import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import { configure } from 'mobx';
import ProtectedRoute from './components/protected-route/ProtectedRoute';
import FullLoader from './components/full-loader/FullLoader';
import AxiosState from './contexts/axios/AxiosState';
import { AsnStoreProvider } from './stores/AsnStore/AsnStore';
import { FullLoaderStoreProvider } from './stores/FullLoaderStore/FullLoaderStore';
import { AuthStoreProvider } from './stores/AuthStore/AuthStore';
import { PartnerStoreProvider } from './stores/PartnerStore/PartnerStore';
import { KioskStoreProvider } from './stores/KioskStore/KioskStore';
import './helpers/i18n';

configure({
  // restricts observable value updating only to actions
  enforceActions: 'observed',
});

ReactDOM.render(
  <AuthStoreProvider>
    <ChakraProvider theme={theme}>
      <FullLoaderStoreProvider>
        <AxiosState>
          <AsnStoreProvider>
            <PartnerStoreProvider>
              <KioskStoreProvider>
                <React.StrictMode>
                  <BrowserRouter>
                    <Switch>
                      <Route path={'/auth/signin'}>
                        <AuthLayout formType={'signin'} />
                      </Route>
                      <Route path={'/auth/signup'}>
                        <AuthLayout formType={'signup'} />
                      </Route>
                      <Route path={'/auth/forgot-password'}>
                        <AuthLayout formType={'forgotPassword'} />
                      </Route>
                      <Route path={'/'}>
                        <ProtectedRoute component={AdminLayout} />
                      </Route>
                    </Switch>
                  </BrowserRouter>
                  <FullLoader></FullLoader>
                </React.StrictMode>
              </KioskStoreProvider>
            </PartnerStoreProvider>
          </AsnStoreProvider>
        </AxiosState>
      </FullLoaderStoreProvider>
    </ChakraProvider>
  </AuthStoreProvider>,
  document.getElementById('root'),
);
