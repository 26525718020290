/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _|
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  FormErrorMessage,
  useToast,
  PinInput,
  PinInputField,
  HStack,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { EMAIL_REGEX_PATTERN } from '../../../variables/regex-expressions';
import { GENERAL } from '../../../variables/general';
import '../../../assets/css/LoginSignUp.css';
import { passwordValidator } from '../../../helpers/helper-functions';
import { useFullLoaderStore } from '../../../stores/FullLoaderStore/FullLoaderStore';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from '../../../stores/AuthStore/AuthStore';
import { useTranslation } from 'react-i18next';
// import { HSeparator } from 'components/separator/Separator';
// import { FcGoogle } from 'react-icons/fc';

const ForgotPassword = observer(() => {
  // Chakra color mode
  const textColor = useColorModeValue('textOnPrimary.500', 'white');
  const textColorSecondary = useColorModeValue('textOnPrimary.500', 'white');
  const textColorDetails = useColorModeValue('brandSecondary.700', 'brandSecondary.600');
  const textColorBrand = useColorModeValue('brandPrimary.500', 'white');
  // const googleBg = useColorModeValue('brandPrimary.200', 'whiteAlpha.200');
  // const googleText = useColorModeValue('brandSecondary.700', 'white');
  // const googleHover = useColorModeValue({ bg: 'brandPrimary.300' }, { bg: 'whiteAlpha.300' });
  // const googleActive = useColorModeValue({ bg: 'brandPrimary.400' }, { bg: 'whiteAlpha.200' });

  const [show, setShow] = useState(false);
  const toast = useToast();
  const fullLoaderStore = useFullLoaderStore();
  const authStore = useAuthStore();
  const otpResendTimeGap = 30; // seconds
  const [resendButtonIsDisabled, setResendButtonIsDisabled] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const user = useRef(null);
  const emailFormValues = {
    email: '',
  };
  const newPasswordFormValues = {
    password: '',
    confirmPassword: '',
  };
  const otpNumberColor = useColorModeValue('black', 'white');
  const [otp, setOtp] = useState(null);
  const otpLength = 6;

  useEffect(() => {
    user.current = { ...authStore.user };
  }, [authStore.user]);

  const handleClick = () => setShow(!show);

  const emailFormValidate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = t('enterEmail');
    } else if (!values.email.toLowerCase().match(EMAIL_REGEX_PATTERN)) {
      errors.email = t('enterEmailInCorrectFormat');
    } else {
      // Nothing to handle
    }
    return errors;
  };

  const newPasswordFormValidate = (values) => {
    const errors = {};
    const passwordChecks = passwordValidator(values.password);

    if (!values.password) {
      errors.password = t('enterPassword');
    } else if (!passwordChecks.isValid) {
      errors.password = passwordChecks.error;
    } else if (!otp) {
      errors.otp = t('enterCode');
    } else if (!values.confirmPassword) {
      errors.confirmPassword = t('confirmPassword');
    } else if (values.confirmPassword !== values.password) {
      errors.confirmPassword = t('passwordShouldMatch');
    } else {
      // Nothing to handle
    }
    return errors;
  };

  const startOtpButtonTimeout = () => {
    setResendButtonIsDisabled(true);
    setTimeout(() => {
      setResendButtonIsDisabled(false);
    }, otpResendTimeGap * 1000);
  };

  const resendOTP = (email) => {
    fullLoaderStore.showFullLoader();
    startOtpButtonTimeout();

    authStore
      .forgotPassword(email)
      .then(() => {
        toast({
          title: t('resetCodeSent'),
          status: 'success',
          duration: GENERAL.TOAST_DURATION,
          isClosable: true,
        });
        fullLoaderStore.hideFullLoader();
      })
      .catch((error) => {
        toast({
          title: error.message || t('somethingWentWrong'),
          status: 'error',
          duration: GENERAL.TOAST_DURATION,
          isClosable: true,
        });
        fullLoaderStore.hideFullLoader();
      });
  };

  const onSubmitEmailForm = (values) => {
    resendOTP(values.email);
  };

  const onSubmitNewPasswordForm = (values, actions) => {
    if (!authStore.user) {
      toast({
        title: t('sendOtpFirst'),
        status: 'error',
        duration: GENERAL.TOAST_DURATION,
        isClosable: true,
      });
      actions.setSubmitting(false);
      return;
    }

    actions.setSubmitting(true);
    fullLoaderStore.showFullLoader();

    values.code = otp;

    authStore
      .verifyForgotPassword(authStore.user, values)
      .then(() => {
        toast({
          title: t('passwordChanged'),
          description: 'Please login',
          status: 'success',
          duration: GENERAL.TOAST_DURATION,
          isClosable: true,
        });

        fullLoaderStore.hideFullLoader();
        actions.setSubmitting(false);
        history.push('/');
      })
      .catch((error) => {
        toast({
          title: error.message || t('somethingWentWrong'),
          status: 'error',
          duration: GENERAL.TOAST_DURATION,
          isClosable: true,
        });
        fullLoaderStore.hideFullLoader();
        actions.setSubmitting(false);
      });
  };

  const otpChange = (otp) => {
    if (otp.length === otpLength) {
      setOtp(otp);
    } else {
      setOtp(null);
    }
  };

  return (
    <Flex
      maxW={{ base: '100%', md: 'max-content' }}
      w="100%"
      mx={{ base: 'auto', lg: '0px' }}
      me="auto"
      h="100%"
      alignItems="start"
      justifyContent="center"
      mb={{ base: '30px', md: '60px' }}
      px={{ base: '25px', md: '0px' }}
      mt={{ base: '40px', md: '14vh' }}
      flexDirection="column"
    >
      <Box me="auto">
        <Heading color={textColor} fontSize="36px" mb="10px">
          {t('forgotPassword')}
        </Heading>
        <Text mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">
          {t('enterEmailToGetCode')}
        </Text>
      </Box>
      <Flex
        zIndex="2"
        direction="column"
        w={{ base: '100%', md: '420px' }}
        maxW="100%"
        background="transparent"
        borderRadius="15px"
        mx={{ base: 'auto', lg: 'unset' }}
        me="auto"
        mb={{ base: '20px', md: 'auto' }}
      >
        <Formik
          initialValues={emailFormValues}
          onSubmit={onSubmitEmailForm}
          validate={emailFormValidate}
        >
          {({ handleSubmit, handleChange, handleBlur }) => (
            <Form onSubmit={handleSubmit}>
              <Field name="email">
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.email && form.touched.email} isRequired>
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      {t('email')}
                    </FormLabel>
                    <Input
                      {...field}
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      type="email"
                      placeholder="johndoe@xyz.com"
                      mb="24px"
                      fontWeight="500"
                      size="lg"
                    />
                    <FormErrorMessage className="login-form-error-message">
                      {form.errors.email}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Button
                fontSize="sm"
                fontWeight="500"
                w="100%"
                h="50"
                variant="brandPrimary"
                m="24px 0"
                type="submit"
                disabled={resendButtonIsDisabled}
              >
                {t('sendResetCode')}
              </Button>
            </Form>
          )}
        </Formik>
        <Box m="16px 0 32px 0">
          <Text mb="32px">{t('confirmOtp')}</Text>
          <HStack>
            <PinInput otp size="lg" onChange={otpChange}>
              <PinInputField color={otpNumberColor} />
              <PinInputField color={otpNumberColor} />
              <PinInputField color={otpNumberColor} />
              <PinInputField color={otpNumberColor} />
              <PinInputField color={otpNumberColor} />
              <PinInputField color={otpNumberColor} />
            </PinInput>
          </HStack>
          {!otp ? (
            <Text m="16px 0 0 0" color="red.500">
              {t('enterResetCode')}
            </Text>
          ) : null}
        </Box>
        <Formik
          initialValues={newPasswordFormValues}
          onSubmit={onSubmitNewPasswordForm}
          validate={newPasswordFormValidate}
        >
          {({ handleSubmit, isSubmitting, handleChange, handleBlur }) => (
            <Form onSubmit={handleSubmit}>
              <Field name="password">
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.password && form.touched.password} isRequired>
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      {t('newPassword')}
                    </FormLabel>
                    <InputGroup size="md">
                      <Input
                        {...field}
                        name="password"
                        fontSize="sm"
                        placeholder="Please enter password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        mb="24px"
                        size="lg"
                        type={show ? 'text' : 'password'}
                        variant="auth"
                      />
                      <InputRightElement display="flex" alignItems="center" mt="4px">
                        <Icon
                          color={textColorSecondary}
                          _hover={{ cursor: 'pointer' }}
                          as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                          onClick={handleClick}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage className="login-form-error-message">
                      {form.errors.password}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="confirmPassword">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.confirmPassword && form.touched.confirmPassword}
                    isRequired
                  >
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      {t('confirmNewPassword')}
                    </FormLabel>
                    <Input
                      {...field}
                      name="confirmPassword"
                      fontSize="sm"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Please confirm password"
                      mb="40px"
                      size="lg"
                      type="password"
                      variant="auth"
                    />
                    <FormErrorMessage className="login-form-error-message">
                      {form.errors.confirmPassword}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Button
                disabled={isSubmitting}
                fontSize="sm"
                variant="brandPrimary"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                type="submit"
              >
                {t('changePassword')}
              </Button>
            </Form>
          )}
        </Formik>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="start"
          maxW="100%"
          mt="0px"
        >
          <Text color={textColorDetails} fontWeight="400" fontSize="14px">
            {t('tryLoginAgain')}
            <NavLink to="/auth/signin">
              <Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
                {t('signIn')}
              </Text>
            </NavLink>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
});

export default ForgotPassword;
