import React from 'react';
// Chakra imports
import { Box, useColorModeValue } from '@chakra-ui/react';
import FixedPlugin from '../../components/fixedPlugin/FixedPlugin';
import AuthIllustration from './AuthIllustration';
import PropTypes from 'prop-types';

// Custom Chakra theme
const Auth = ({ formType }) => {
  // states and functions
  const authBg = useColorModeValue('white', 'brandSecondary.900');
  return (
    <Box
      bg={authBg}
      minHeight="100vh"
      transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
      transitionDuration=".2s, .2s, .35s"
      transitionProperty="top, bottom, width"
      transitionTimingFunction="linear, linear, ease"
    >
      <AuthIllustration formType={formType} />
      <FixedPlugin />
    </Box>
  );
};

Auth.propTypes = {
  formType: PropTypes.string.isRequired,
};

export default Auth;
