import {
  Box,
  Button,
  CloseButton,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { GENERAL } from '../../../../variables/general';
import { useAuthStore } from '../../../../stores/AuthStore/AuthStore';
import { useKioskStore } from '../../../../stores/KioskStore/KioskStore';
import { useTranslation } from 'react-i18next';

export interface AttachKioskModalProps {
  asn: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const AttachKioskModal = observer(({ asn, isOpen, onOpen, onClose }: AttachKioskModalProps) => {
  const kioskStore = useKioskStore();
  const authStore = useAuthStore();
  const toast = useToast();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [enteredDsn, setEnteredDsn] = useState('');
  const [gettingKiosk, setGettingKiosk] = useState(false);
  const [attachingKiosk, setAttachingKiosk] = useState(false);
  const { t } = useTranslation();

  const getKioskByDsn = () => {
    setGettingKiosk(true);
    kioskStore
      .getKioskByDsn(enteredDsn)
      .then(() => {
        setGettingKiosk(false);
      })
      .catch((error: any) => {
        toast({
          title: error.message || t('somethingWentWrong'),
          status: 'error',
          duration: GENERAL.TOAST_DURATION,
          isClosable: true,
        });
        setGettingKiosk(false);
      });
  };

  const attachKioskToAsn = () => {
    if (!kioskStore.kiosk || !authStore.userData) return;

    setAttachingKiosk(true);
    kioskStore
      .attachKioskToAsn(kioskStore.kiosk._id, asn, authStore.userData)
      .then(() => {
        toast({
          title: `Kiosk - ${kioskStore?.kiosk?._id}, ${t('linkedToAsn')} - ${asn}`,
          status: 'success',
          duration: null,
          isClosable: true,
        });
        setAttachingKiosk(false);
        onClose();
      })
      .catch((error: any) => {
        toast({
          title: error.message || t('somethingWentWrong'),
          status: 'error',
          duration: GENERAL.TOAST_DURATION,
          isClosable: true,
        });
        setAttachingKiosk(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent width={{ sm: '80vw', md: '50vw' }}>
        <Flex justifyContent="flex-end">
          <CloseButton onClick={onClose}></CloseButton>
        </Flex>
        <ModalHeader>{t('findDSNToAttach')}</ModalHeader>
        <ModalBody>
          <Input
            pattern="/^[A-Za-z0-9]*$/"
            value={enteredDsn}
            placeholder={t('enterDSN') as string}
            borderRadius="16px"
            onChange={(e) => setEnteredDsn(e.target.value)}
            color={textColor}
          />
          <Button
            onClick={getKioskByDsn}
            disabled={enteredDsn.length === 0}
            marginTop="1rem"
            variant="action"
            isLoading={gettingKiosk}
          >
            {t('find')} Kiosk
          </Button>
          {kioskStore.kiosk && (
            <Box m="2rem 0">
              <Text marginBottom="1rem" color={textColor}>
                Kiosk {t('found')}:
              </Text>
              <Text color={textColor}>DSN: {kioskStore.kiosk._id}</Text>
              <Text color={textColor}>IMEI: {kioskStore.kiosk.imei}</Text>
              <Text color={textColor}>
                {t('label')}: {kioskStore.kiosk.label}
              </Text>
              <Text color={textColor}>
                {t('status')}: {kioskStore.kiosk.status}
              </Text>
              <Text color={textColor}>
                {t('type')}: {kioskStore.kiosk.type}
              </Text>
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={attachingKiosk}
            onClick={attachKioskToAsn}
            disabled={!kioskStore.kiosk}
            variant="action"
          >
            {t('confirmLinking')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export default AttachKioskModal;
