import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const GoPowerLogoMark = createIcon({
  displayName: 'GoPower Logo',
  viewBox: '0 0 793 1308',
  path: (
    <svg id="图层_1" data-name="图层 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 793 1308">
      <path
        fill="currentColor"
        d="M91.14,281.89c8.55,1.53,7.32,5.63,5.8,11.4-7.73,29.24-16.11,58.39-21.88,88-7.21,37.08-5.71,74.65.25,111.87,10.32,64.43,34.6,123,76.67,173.27,52.5,62.78,118.51,102.95,200.25,114.34,108.28,15.09,199.14-20.56,274.41-98,47.78-49.15,75.8-109.15,88.92-175.7,14-70.89,7.17-140.7-16.77-208.9-1.58-4.51-8.64-10.54-.48-14.71,6.91-3.54,8.69,4.6,11.58,8.17,38,47.08,60.78,101,70.18,160.59,12.14,77,2.29,151.39-31.12,221.58-24.14,50.74-51.33,100.06-78.09,149.51Q547.75,1050.87,423.94,1278c-.46.85-.83,1.74-1.34,2.55-6.5,10.28-14,20.12-27.36,20-12.48-.14-18.59-10.07-23.88-19.81q-125.8-231.47-251.48-463c-23-42.46-46.34-84.8-68.51-127.72A381.33,381.33,0,0,1,10.92,555C1.37,466.79,19,385.19,68.86,311.27,75.81,301,83.86,291.45,91.14,281.89Z"
      />
      <path
        fill="#da8f0c"
        className="cls-1"
        d="M446.37,218.33c13.78,0,26.61-.59,39.38.13,23.31,1.3,46.63,2.94,69.86,5.29,11.71,1.19,13.06,5.48,6.09,14.87A1681.5,1681.5,0,0,0,391.65,522.25c-9.8,20.73-17,42.7-25.34,64.11-1,2.57-2,5.16-3,7.74l-2.72,0c-1.32-7.52-2.94-15-3.92-22.58q-7.15-55.14-14-110.34c-4.45-35.84-8.94-71.68-13-107.57-3.08-27.33-5.48-54.73-8.2-82.1-.6-6.05-1.52-12.09-1.82-18.15-.34-6.9-3.81-9.07-10.14-7.65Q286.2,251,262.9,256.31c-11.12,2.55-13.42.67-10.65-10.33,15.26-60.62,30.42-121.27,46.6-181.64,1.79-6.68,8.66-13.84,15.06-17.15,53.53-27.68,110.43-41.41,171-38.74,15.35.68,19.75,6.77,15.44,21.67q-26.46,91.46-52.89,182.93C447.08,214.24,446.93,215.49,446.37,218.33Z"
      />
    </svg>
  ),
});

export const GoPowerLogo = createIcon({
  displayName: 'GoPower Logo',
  viewBox: '0 0 597 191.91',
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" height="191.91" width="597">
      <path
        fill="currentColor"
        d="M199.8,130.3a8.21,8.21,0,0,1-.13,1.5,3.41,3.41,0,0,1-.37,1.08,1.47,1.47,0,0,1-.63.61,1.85,1.85,0,0,1-.81.18,6.89,6.89,0,0,1-2.51-.85,44.82,44.82,0,0,0-4.69-1.89,55.89,55.89,0,0,0-7-1.89,48.15,48.15,0,0,0-9.73-.86,38.2,38.2,0,0,0-12,1.76,27,27,0,0,0-9,4.85,21,21,0,0,0-5.66,7.38,23.24,23.24,0,0,0,.19,19.15,20.94,20.94,0,0,0,6,7.28,26.74,26.74,0,0,0,9.1,4.5,41.71,41.71,0,0,0,11.61,1.53,44.86,44.86,0,0,0,7.6-.66,35.53,35.53,0,0,0,7.17-1.95v-17H170.08a1.82,1.82,0,0,1-1.6-.77,4.23,4.23,0,0,1-.53-2.38,7.68,7.68,0,0,1,.12-1.46,2.64,2.64,0,0,1,.41-1,1.83,1.83,0,0,1,.66-.56,2.16,2.16,0,0,1,.94-.18h25.71a5.45,5.45,0,0,1,1.38.18,4.38,4.38,0,0,1,1.25.54,2.49,2.49,0,0,1,.88,1,3.31,3.31,0,0,1,.31,1.44V174a3.55,3.55,0,0,1-.57,2,4.6,4.6,0,0,1-2.37,1.51c-1.21.43-2.78.91-4.7,1.42s-3.9.94-5.94,1.3-4.11.63-6.19.81-4.15.27-6.2.27a61.85,61.85,0,0,1-16.77-2.09,37.31,37.31,0,0,1-12.48-6,26.3,26.3,0,0,1-7.79-9.26,28.41,28.41,0,0,1,.22-24.59,27.83,27.83,0,0,1,8.2-9.58,39.71,39.71,0,0,1,12.7-6.13,57.85,57.85,0,0,1,16.42-2.18,61.17,61.17,0,0,1,8.7.58q4.07.59,7.23,1.37a36.65,36.65,0,0,1,5.31,1.71,17.37,17.37,0,0,1,3,1.51,3.16,3.16,0,0,1,1.12,1.35A6,6,0,0,1,199.8,130.3Z"
      />
      <path
        fill="currentColor"
        d="M325,139.42a15.54,15.54,0,0,1-2,7.87,17.68,17.68,0,0,1-5.72,6,29.12,29.12,0,0,1-9.14,3.82,54,54,0,0,1-12.92,1.35H286v20.95a.84.84,0,0,1-.28.63,2,2,0,0,1-.87.43,13.35,13.35,0,0,1-1.63.27c-.69.07-1.55.11-2.6.11s-1.9,0-2.59-.11a14.75,14.75,0,0,1-1.66-.27,1.85,1.85,0,0,1-.88-.43.93.93,0,0,1-.25-.63V125.71a2.72,2.72,0,0,1,1.32-2.56,5.7,5.7,0,0,1,2.94-.76h17.33q2.63,0,5,.15c1.6.11,3.5.33,5.69.68a29.24,29.24,0,0,1,6.69,1.93,21,21,0,0,1,5.79,3.48,14.42,14.42,0,0,1,3.66,4.79A13.86,13.86,0,0,1,325,139.42Zm-11.32.63a9.41,9.41,0,0,0-1.85-5.93,11.37,11.37,0,0,0-4.56-3.55,19.54,19.54,0,0,0-5.63-1.49,52.66,52.66,0,0,0-5.67-.31H286V152.1h9.7a30.73,30.73,0,0,0,8.11-.9,16.92,16.92,0,0,0,5.41-2.49,10.72,10.72,0,0,0,3.31-3.82A10.61,10.61,0,0,0,313.7,140.05Z"
      />
      <path
        fill="currentColor"
        d="M481.84,138.88a3.09,3.09,0,0,1-.12.77,8.39,8.39,0,0,1-.44,1.12L464.7,179.21a1.86,1.86,0,0,1-.59.81,3,3,0,0,1-1.13.49,10.5,10.5,0,0,1-2,.25c-.84.05-1.88.07-3.13.07s-2.38,0-3.25-.09a14,14,0,0,1-2.1-.27,2.8,2.8,0,0,1-1.16-.49,2,2,0,0,1-.56-.77L439,149.85l-.13-.4-.13.4-10.94,29.36a1.88,1.88,0,0,1-.6.81,3,3,0,0,1-1.22.49,12.58,12.58,0,0,1-2.09.25c-.86.05-1.91.07-3.16.07s-2.35,0-3.16-.09a13.65,13.65,0,0,1-2-.27,2.75,2.75,0,0,1-1.15-.49,2.07,2.07,0,0,1-.57-.77l-16.45-38.44a7.21,7.21,0,0,1-.44-1.12,3.16,3.16,0,0,1-.13-.77.87.87,0,0,1,.26-.65,1.82,1.82,0,0,1,.84-.4,7.57,7.57,0,0,1,1.59-.2c.67,0,1.49,0,2.44,0,1.17,0,2.11,0,2.82.07a8.54,8.54,0,0,1,1.63.22,1.72,1.72,0,0,1,.81.45,3.21,3.21,0,0,1,.44.7l13.58,33.36.12.4.13-.4L433.92,139a1.81,1.81,0,0,1,.4-.7,1.94,1.94,0,0,1,.85-.45,7.91,7.91,0,0,1,1.53-.22c.65-.05,1.49-.07,2.54-.07s1.82,0,2.47.07a8.16,8.16,0,0,1,1.53.22,1.75,1.75,0,0,1,.81.43,1.83,1.83,0,0,1,.38.63l13.45,33.45.13.4.06-.4L471.46,139a1.81,1.81,0,0,1,.4-.7,2,2,0,0,1,.88-.45,9,9,0,0,1,1.63-.22c.69-.05,1.57-.07,2.66-.07s1.8,0,2.4,0a5.45,5.45,0,0,1,1.44.22,2,2,0,0,1,.76.41A.86.86,0,0,1,481.84,138.88Z"
      />
      <path
        fill="currentColor"
        d="M540.39,157.45a2.69,2.69,0,0,1-1.23,2.5,5.35,5.35,0,0,1-2.8.74H499.28a18.29,18.29,0,0,0,.94,6.07,10.9,10.9,0,0,0,3.14,4.63,14.93,14.93,0,0,0,5.73,3,30.84,30.84,0,0,0,8.61,1,49.45,49.45,0,0,0,7.17-.47c2.09-.32,3.91-.67,5.44-1.06s2.78-.74,3.77-1.06a8.25,8.25,0,0,1,2.23-.47,2,2,0,0,1,.79.16,1.21,1.21,0,0,1,.53.47,2.4,2.4,0,0,1,.28.88,7.61,7.61,0,0,1,.1,1.37,7.31,7.31,0,0,1-.06,1c-.05.29-.1.54-.16.77a1.72,1.72,0,0,1-.31.6,3.24,3.24,0,0,1-.57.52,11.13,11.13,0,0,1-2,.81,39.47,39.47,0,0,1-4.38,1.1q-2.68.54-6.23,1a63.92,63.92,0,0,1-7.54.42A47.36,47.36,0,0,1,504.57,180a24.68,24.68,0,0,1-8.8-4.14,17.35,17.35,0,0,1-5.38-6.87,23.81,23.81,0,0,1-1.81-9.63,22.56,22.56,0,0,1,1.88-9.37,19.29,19.29,0,0,1,5.41-7.06,25,25,0,0,1,8.54-4.45,37.9,37.9,0,0,1,11.2-1.55,36.47,36.47,0,0,1,11.29,1.53,22.35,22.35,0,0,1,7.67,4.11,15.8,15.8,0,0,1,4.41,6.07,19.7,19.7,0,0,1,1.41,7.44ZM530,155.25q.18-5.85-3.62-9.17t-11.3-3.33a20.27,20.27,0,0,0-6.74,1,14.81,14.81,0,0,0-4.84,2.75,12.05,12.05,0,0,0-3,4,12.46,12.46,0,0,0-1.19,4.74Z"
      />
      <path
        fill="currentColor"
        d="M589,141.63c0,.66,0,1.21-.06,1.66a3.24,3.24,0,0,1-.25,1.06,1.54,1.54,0,0,1-.47.58,1.29,1.29,0,0,1-.78.2,4.34,4.34,0,0,1-1.22-.2c-.48-.13-1-.27-1.63-.4s-1.28-.27-2-.39a16.94,16.94,0,0,0-2.44-.18,11,11,0,0,0-3.07.45,13.14,13.14,0,0,0-3.16,1.49,22.35,22.35,0,0,0-3.47,2.74q-1.81,1.71-4,4.18v26.62a.79.79,0,0,1-.25.6,2,2,0,0,1-.81.43,7.71,7.71,0,0,1-1.56.27c-.67.06-1.53.09-2.57.09s-1.84,0-2.5-.09a8.33,8.33,0,0,1-1.6-.27,1.72,1.72,0,0,1-.81-.43.84.84,0,0,1-.22-.6V139a.92.92,0,0,1,.19-.6,1.5,1.5,0,0,1,.75-.45,5.13,5.13,0,0,1,1.44-.27c.58-.05,1.35-.07,2.31-.07s1.68,0,2.29.07a4.49,4.49,0,0,1,1.4.27,1.72,1.72,0,0,1,.72.45.84.84,0,0,1,.22.6v5.89a36.34,36.34,0,0,1,4.35-4,24.11,24.11,0,0,1,3.85-2.41,14.41,14.41,0,0,1,3.6-1.21,18.74,18.74,0,0,1,3.59-.34c.55,0,1.16,0,1.85.07a21.72,21.72,0,0,1,2.16.25c.75.12,1.43.25,2,.4a5,5,0,0,1,1.28.45,1.39,1.39,0,0,1,.5.43,1.86,1.86,0,0,1,.22.51,3.73,3.73,0,0,1,.13.93C589,140.39,589,140.94,589,141.63Z"
      />
      <path
        className="cls-1"
        fill="#da8f0c"
        d="M251.34,137.16l-4.35,0c-2.55,0-5.1-.06-7.66,0q-1.07,0-2.13.06c-.56,0-1.1.07-1.64.13q-.47.06-.93.09A20.86,20.86,0,0,0,224,141.13a22.47,22.47,0,0,0-3.85,3.28l-.26.3a21.16,21.16,0,0,0-1.72,2.16l0,0a12.4,12.4,0,0,0-2.19,6.92l-.24,19.62a8.7,8.7,0,0,0,8.09,8.78l4.36,0c2.48,0,5,.06,7.45,0h0q1.06,0,2.13-.06c.56,0,1.1-.07,1.64-.13l.93-.09a20.82,20.82,0,0,0,10.68-3.73,23,23,0,0,0,3.85-3.29,22.71,22.71,0,0,1-5.64,4.53A23.18,23.18,0,0,0,255,175l.26-.3A21.16,21.16,0,0,0,257,172.5l0,0a12.32,12.32,0,0,0,2.19-6.92l.24-19.62A8.7,8.7,0,0,0,251.34,137.16ZM237.47,176.4a16.25,16.25,0,0,1-4.26-.51,15.11,15.11,0,0,1-1.49-.46c.4.16.82.32,1.24.46a16.46,16.46,0,0,1-12-12.62c.13.5.29,1,.47,1.49a18.7,18.7,0,0,0,2.48,4.72,16.27,16.27,0,0,1-2.7-6.21,16.47,16.47,0,1,1,16.3,13.13Z"
      />
      <path
        className="cls-1"
        fill="#da8f0c"
        d="M339.16,145.93l.24,19.62a12.4,12.4,0,0,0,2.19,6.92l0,0a21.16,21.16,0,0,0,1.72,2.16l.26.3a22.53,22.53,0,0,0,3.85,3.29c.67.45,1.35.87,2,1.24a22.71,22.71,0,0,1-5.64-4.53,23,23,0,0,0,3.85,3.29A20.82,20.82,0,0,0,358.37,182l.93.09c.54.06,1.08.1,1.64.13s1.42.05,2.13.06H363c2.48,0,5,0,7.46,0l4.35,0a8.7,8.7,0,0,0,8.09-8.78l-.24-19.62a12.32,12.32,0,0,0-2.19-6.92l0,0a21.16,21.16,0,0,0-1.72-2.16l-.26-.3a22.47,22.47,0,0,0-3.85-3.28A20.86,20.86,0,0,0,364,137.39q-.46,0-.93-.09c-.54-.06-1.08-.1-1.64-.13s-1.42,0-2.13-.06c-2.55-.06-5.1,0-7.65,0l-4.36,0A8.7,8.7,0,0,0,339.16,145.93Zm5.66,13.83a16.47,16.47,0,1,1,32.6,3.51,16.27,16.27,0,0,1-2.7,6.21,18.7,18.7,0,0,0,2.48-4.72c.18-.49.34-1,.47-1.49a16.46,16.46,0,0,1-12,12.62c.42-.14.84-.3,1.24-.46a15.11,15.11,0,0,1-1.49.46,16.25,16.25,0,0,1-4.26.51A16.47,16.47,0,0,1,344.82,159.76Z"
      />
      <path
        fill="currentColor"
        d="M18.78,45.15c1.16.2,1,.76.79,1.55-1.05,4-2.19,7.93-3,12a43.3,43.3,0,0,0,0,15.19A47.65,47.65,0,0,0,27,97.39c7.13,8.53,16.1,14,27.21,15.54,14.71,2,27.05-2.8,37.28-13.32a46.27,46.27,0,0,0,12.08-23.87,53.37,53.37,0,0,0-2.28-28.37c-.22-.62-1.18-1.44-.07-2,.94-.48,1.18.62,1.57,1.11a45.25,45.25,0,0,1,9.54,21.81,50.41,50.41,0,0,1-4.23,30.11c-3.28,6.89-7,13.59-10.61,20.31Q80.8,149.61,64,180.47c-.06.11-.11.23-.18.34-.89,1.4-1.91,2.74-3.72,2.71s-2.53-1.36-3.25-2.69q-17.08-31.44-34.16-62.89c-3.13-5.77-6.29-11.53-9.31-17.36A51.75,51.75,0,0,1,7.88,82.24c-1.3-12,1.1-23.06,7.87-33.1C16.69,47.74,17.79,46.45,18.78,45.15Z"
      />
      <path
        className="cls-1"
        fill="#da8f0c"
        d="M67,36.51c1.87,0,3.61-.08,5.34,0,3.17.18,6.34.4,9.5.72,1.59.16,1.77.74.82,2A228.48,228.48,0,0,0,59.6,77.8c-1.33,2.82-2.3,5.8-3.44,8.71-.14.35-.27.7-.4,1h-.37c-.18-1-.4-2-.53-3.07q-1-7.48-1.91-15c-.6-4.87-1.21-9.74-1.76-14.61-.42-3.71-.75-7.44-1.12-11.16-.08-.82-.2-1.64-.24-2.46s-.52-1.23-1.38-1l-6.34,1.43c-1.51.35-1.82.09-1.45-1.4C42.74,32,44.8,23.8,47,15.59a4.35,4.35,0,0,1,2-2.33A45.48,45.48,0,0,1,72.26,8c2.09.09,2.69.92,2.1,2.95Q70.78,23.37,67.18,35.8A7.22,7.22,0,0,0,67,36.51Z"
      />
    </svg>
  ),
});
