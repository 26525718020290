import {
  Box,
  Button,
  CloseButton,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { GENERAL } from '../../../../variables/general';
import { useAuthStore } from '../../../../stores/AuthStore/AuthStore';
import { usePartnerStore } from '../../../../stores/PartnerStore/PartnerStore';
import { useTranslation } from 'react-i18next';

export interface AssignPartnerModalProps {
  asn: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const AssignPartnerModal = observer(({ asn, isOpen, onOpen, onClose }: AssignPartnerModalProps) => {
  const partnerStore = usePartnerStore();
  const authStore = useAuthStore();
  const toast = useToast();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [enteredEmail, setEnteredEmail] = useState('');
  const [gettingPartner, setGettingPartner] = useState(false);
  const [assigningPartner, setAssigningPartner] = useState(false);
  const { t } = useTranslation();

  const getPartnerByEmail = () => {
    setGettingPartner(true);
    partnerStore
      .getPartnerByEmail(enteredEmail)
      .then(() => {
        setGettingPartner(false);
      })
      .catch((error: any) => {
        toast({
          title: error.message || t('somethingWentWrong'),
          status: 'error',
          duration: GENERAL.TOAST_DURATION,
          isClosable: true,
        });
        setGettingPartner(false);
      });
  };

  const assignPartnerToAsn = () => {
    if (!partnerStore.partner?.email || !authStore.userData) return;

    setAssigningPartner(true);
    partnerStore
      .assignPartnerToAsn(partnerStore.partner.email, asn, authStore.userData)
      .then(() => {
        toast({
          title: `${t('partnerLinkedToAsn')} - ${asn}`,
          status: 'success',
          duration: null,
          isClosable: true,
        });
        setAssigningPartner(false);
        onClose();
      })
      .catch((error: any) => {
        toast({
          title: error.message || t('somethingWentWrong'),
          status: 'error',
          duration: GENERAL.TOAST_DURATION,
          isClosable: true,
        });
        setAssigningPartner(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent width={{ sm: '80vw', md: '50vw' }}>
        <Flex justifyContent="flex-end">
          <CloseButton onClick={onClose}></CloseButton>
        </Flex>
        <ModalHeader>{t('findPartnerToAssign')}</ModalHeader>
        <ModalBody>
          <Input
            value={enteredEmail}
            placeholder="Enter partner email"
            borderRadius="16px"
            onChange={(e) => setEnteredEmail(e.target.value)}
            pattern="/^[A-Za-z0-9]*$/"
            color={textColor}
          />
          <Button
            onClick={getPartnerByEmail}
            disabled={enteredEmail.length === 0}
            marginTop="1rem"
            variant="action"
            isLoading={gettingPartner}
          >
            {t('findPartner')}
          </Button>
          {partnerStore.partner && (
            <Box m="2rem 0">
              <Text marginBottom="1rem" color={textColor}>
                {t('partnerFound')}:
              </Text>
              <Text color={textColor}>
                {t('email')}: {partnerStore.partner.email}
              </Text>
              <Text color={textColor}>
                {t('enabled')}: {partnerStore.partner.Enabled ? 'true' : 'false'}
              </Text>
              <Text color={textColor}>
                {t('userStatus')}: {partnerStore.partner.UserStatus}
              </Text>
              <Text color={textColor}>
                {t('createdDate')}: {partnerStore.partner.UserCreateDate}
              </Text>
              <Text color={textColor}>
                {t('lastModifiedDate')}: {partnerStore.partner.UserLastModifiedDate}
              </Text>
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={assigningPartner}
            onClick={assignPartnerToAsn}
            disabled={!partnerStore.partner}
            variant="action"
          >
            {t('confirmAssign')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export default AssignPartnerModal;
