/*eslint-disable*/
import React from 'react';
import { Flex, Link, List, ListItem, Text, useColorModeValue } from '@chakra-ui/react';
import { GENERAL } from '../../variables/general';
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const textColor = useColorModeValue('gray.400', 'white');
  const { t } = useTranslation();

  return (
    <Flex
      zIndex="3"
      flexDirection={{
        base: 'column',
        xl: 'row',
      }}
      alignItems={{
        base: 'center',
        xl: 'start',
      }}
      justifyContent="space-between"
      px={{ base: '30px', md: '50px' }}
      pb="30px"
    >
      <Text
        color={textColor}
        textAlign={{
          base: 'center',
          xl: 'start',
        }}
        mb={{ base: '20px', xl: '0px' }}
      >
        {' '}
        &copy; {1900 + new Date().getYear()}
        <Text as="span" fontWeight="500" ms="4px">
          {t('rightsReservedAndMadeBy')}&nbsp;
          <Link
            mx="3px"
            color="#E91D25"
            href={GENERAL.HANABI_LANDING}
            target="_blank"
            fontWeight="700"
          >
            Hanabi Technologies!
          </Link>
        </Text>
      </Text>
      <List display="flex">
        <ListItem
          me={{
            base: '20px',
            md: '44px',
          }}
        >
          <Link fontWeight="500" color={textColor} href={GENERAL.TERMS_AND_CONDITIONS}>
            {t('termsAndConditions')}
          </Link>
        </ListItem>
        <ListItem>
          <Link fontWeight="500" color={textColor} href={GENERAL.PRIVACY_POLICY}>
            {t('privacyPolicy')}
          </Link>
        </ListItem>
      </List>
    </Flex>
  );
}
