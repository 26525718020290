export const passwordValidator = (pass: string) => {
  pass = pass.trim();

  const result: {
    isValid: boolean;
    error: null | string;
  } = {
    isValid: true,
    error: null,
  };

  // prettier-ignore
  const specialCharArray = [
    ' ',
    '^',
    '$',
    '*',
    '.',
    '[',
    ']',
    '{',
    '}',
    '(',
    ')',
    '?',
    '\'',
    '!',
    '@',
    '#',
    '%',
    '&',
    '/',
    '\\',
    ',',
    '>',
    '<',
    '\'',
    ':',
    ';',
    '|',
    '_',
    '~',
    '`',
    '=',
    '+',
    '-',
  ];

  if (pass.length < 6 || pass.length >= 99) {
    result.isValid = false;
    result.error = 'Password must be between 5 and 99 characters';
    return result;
  }

  const atLeastOne: Record<string, boolean> = {
    number: false,
    specialChar: false,
    uppercase: false,
    lowercase: false,
  };

  for (let i = 0; i < pass.length; i++) {
    if ('0' <= pass.charAt(i) && pass.charAt(i) <= '9') {
      atLeastOne.number = true;
      continue;
    }
    if ('a' <= pass.charAt(i) && pass.charAt(i) <= 'z') {
      atLeastOne.lowercase = true;
      continue;
    }
    if ('A' <= pass.charAt(i) && pass.charAt(i) <= 'Z') {
      atLeastOne.uppercase = true;
      continue;
    }
    if (specialCharArray.includes(pass.charAt(i))) {
      atLeastOne.specialChar = true;
      continue;
    }
  }

  Object.keys(atLeastOne).forEach((key) => {
    if (!atLeastOne[key]) {
      result.isValid = false;

      switch (key) {
        case 'number':
          result.error = 'Password must have at least one number';
          break;
        case 'specialChar':
          result.error = 'Password must have at least one special character';
          break;
        case 'uppercase':
          result.error = 'Password must have at least one uppercase letter';
          break;
        case 'lowercase':
          result.error = 'Password must have at least one lowercase letter';
          break;
        default:
        // Nothing to do
      }
    }
  });

  return result;
};
