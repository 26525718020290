import {
  Flex,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  TableContainer,
  CircularProgress,
  Button,
} from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import { useTable, Row } from 'react-table';
import { observer } from 'mobx-react-lite';
import { TableColumn } from '../../variables/generalTypes';
import { useTranslation } from 'react-i18next';

export interface ShowMoreTableProps {
  columnsData: Array<TableColumn>;
  tableData: Array<any>;
  onShowMore: () => Promise<void>;
  loading: boolean;
  paginationToken: string | undefined;
  renderRow: (
    columnsData: Array<TableColumn>,
    row: Row<any>,
    textColor: string,
  ) => Array<JSX.Element>;
}

const ShowMoreTable = observer(
  ({
    columnsData,
    tableData,
    onShowMore,
    loading,
    renderRow,
    paginationToken,
  }: ShowMoreTableProps) => {
    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);
    const { t } = useTranslation();

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable<any>({
      columns,
      data,
    });

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

    useEffect(() => {
      onShowMore();
    }, []);

    if (loading && !paginationToken) {
      return (
        <Flex flex={1} justifyContent="center" alignItems="center">
          <CircularProgress
            size="36px"
            thickness="4px"
            color="brandPrimary.500"
            isIndeterminate
          ></CircularProgress>
        </Flex>
      );
    }

    return (
      <>
        <TableContainer>
          <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps()}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: '10px', lg: '12px' }}
                        color="gray.400"
                      >
                        {column.render('Header')}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {renderRow(columnsData, row, textColor)}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
        <Flex justifyContent="space-between" mt="2rem" alignItems="center">
          {paginationToken && (
            <Button
              fontSize="sm"
              variant="brandPrimary"
              fontWeight="500"
              width={{ md: 'auto', sm: '100%' }}
              mb="24px"
              onClick={onShowMore}
              isLoading={loading}
            >
              {t('showMore')}
            </Button>
          )}
        </Flex>
      </>
    );
  },
);

export default ShowMoreTable;
