/* eslint-disable */

import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import createStoreContext from '../createStoreContext';
import { API_ROUTES } from '../../variables/api';
import { ApiResponse } from '../../variables/generalTypes';
import { UserData } from 'amazon-cognito-identity-js';
import i18n from '../../helpers/i18n';

export interface Attribute {
  Name: string;
  Value: string;
}

export interface Partner {
  Enabled: boolean;
  UserCreateDate: Date;
  UserLastModifiedDate: Date;
  UserStatus: string;
  Username: string;
  Attributes: Array<Attribute>;
  sub?: string;
  'custom:accId': string;
  email_verified?: string;
  email?: string;
}

export interface PartnerListTableDataRow {
  email: string;
  enabled: 'true' | 'false';
  createdDate: string;
  lastModifiedDate: string;
}

export const [PartnerStoreProvider, usePartnerStore] = createStoreContext(
  class PartnerStore {
    private readonly apiKey: string | undefined = process.env.REACT_APP_API_ENDPOINT;
    partner: Partner | null | undefined = null;
    partnerList: Array<Partner> = [];
    paginationToken: string | undefined;

    public constructor() {
      // NOTE: don't add anything else here  unless you know what you are doing
      // as the constructor will be executed twice due to `StrictMode`
      makeAutoObservable(this);
    }

    getPartnerByEmail = async (email: string) => {
      const res = await axios.post<ApiResponse<Partner>>(
        this.apiKey + API_ROUTES.GET_PARTNER_BY_EMAIL,
        { email },
      );

      if (res.data.status === 'success') {
        this.partner = res.data.data;
        return res.data.data;
      } else if (res.data.status === 'error' || res.data.status === 'fail') {
        throw new Error(res.data.message || (i18n.t('somethingWentWrong') as string));
      } else {
        throw new Error(i18n.t('unhandledScenario') as string);
      }
    };

    getPartnerList = async (limit?: number) => {
      const res = await axios.post<
        ApiResponse<{
          users: Array<Partner>;
          paginationToken?: string;
        }>
      >(this.apiKey + API_ROUTES.GET_PARTNER_LIST, {
        paginationToken: this.paginationToken,
        limit,
      });

      if (res.data.status === 'success') {
        if (this.paginationToken) {
          // We want to increment to results
          const toConcat = res.data.data?.users ?? [];
          this.partnerList = [...this.partnerList, ...toConcat];
        } else {
          this.partnerList = res.data.data?.users ?? [];
        }
        this.paginationToken = res.data.data?.paginationToken;
      } else if (res.data.status === 'error' || res.data.status === 'fail') {
        throw new Error(res.data.message || (i18n.t('somethingWentWrong') as string));
      } else {
        throw new Error(i18n.t('unhandledScenario') as string);
      }
    };

    public getDataForPartnerListTable = (): Array<PartnerListTableDataRow> => {
      if (this.partnerList) {
        return this.partnerList.map((partner) => {
          return {
            email: partner.Attributes.find((x) => x.Name === 'email')?.Value ?? 'NA',
            enabled: partner.Enabled ? 'true' : 'false',
            createdDate: new Date(partner.UserCreateDate).toDateString(),
            lastModifiedDate: new Date(partner.UserLastModifiedDate).toDateString(),
          };
        });
      }
      return [];
    };

    assignPartnerToAsn = async (partnerEmail: string, asn: string, userData: UserData) => {
      const sub = userData.Username;
      const email = userData.UserAttributes.find((attr) => attr.Name === 'email')?.Value;
      const team = email?.split('@')[email?.split('@').length - 1];
      if (!sub || !email || !team) throw new Error(i18n.t('paramsNotDefined') as string);

      const res = await axios.post<ApiResponse<Partner>>(
        this.apiKey + API_ROUTES.ASSIGN_PARTNER_TO_ASN,
        { partnerEmail, email, sub, team, asn },
      );

      if (res.data.status === 'success') {
        this.partner = res.data.data;
        return res.data.data;
      } else if (res.data.status === 'error' || res.data.status === 'fail') {
        throw new Error(res.data.message || (i18n.t('somethingWentWrong') as string));
      } else {
        throw new Error(i18n.t('unhandledScenario') as string);
      }
    };
  },
);

export type GlobalStore = ReturnType<typeof usePartnerStore>;
