import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthStore } from '../../stores/AuthStore/AuthStore';

const ProtectedRoute = ({ component }) => {
  const authStore = useAuthStore();

  return (
    <Route
      render={() =>
        authStore.isAuthenticated ? React.createElement(component) : <Redirect to="/auth/signin" />
      }
    />
  );
};

export default ProtectedRoute;
