/* eslint-disable */

import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import createStoreContext from '../createStoreContext';
import { API_ROUTES } from '../../variables/api';
import { UserData } from 'amazon-cognito-identity-js';
import { ApiResponse } from '../../variables/generalTypes';
import i18n from '../../helpers/i18n';

export interface Owner {
  role: string;
  _id: string;
}

export interface Price {
  currency: string;
  unitAmount: number;
  unitSecond: number;
  _id: string;
}

export interface RevenueShare {
  host: number;
  manager: number;
  platform: number;
  _id: string;
}

export interface Manager {
  accId: string;
  sub: string;
  _id: {
    $oid: string;
  };
}

export interface Supplier {
  email: string;
  sub: string;
  team: string;
  _id: string;
}
export interface Asn {
  owner: Owner;
  price: Price;
  revenueShare: RevenueShare;
  status: string;
  __v: 0;
  _id: string;
  dsn?: string;
  manager?: Manager;
  createdAt: Date;
  supplier?: Supplier;
}

export interface GetAsnList {
  docs: Array<Asn>;
  limit: number;
  next: number;
  page: number;
  pages: number;
  previous: null | number;
  total: number;
}

export const [AsnStoreProvider, useAsnStore] = createStoreContext(
  class AsnStore {
    private readonly apiKey: string | undefined = process.env.REACT_APP_API_ENDPOINT;
    asnList: Array<Asn> | null = null;
    asnListPageCount = 0;
    error: any = null;
    generatedAsn: null | string = null;

    public constructor() {
      // NOTE: don't add anything else here  unless you know what you are doing
      // as the constructor will be executed twice due to `StrictMode`
      makeAutoObservable(this);
    }

    public getDataForAsnListTable = () => {
      if (this.asnList) {
        return this.asnList.map((asn) => {
          return {
            id: asn._id,
            status: asn.status,
            owner: asn.owner,
            dsn: asn.dsn,
            manager: asn.manager,
            createdAt: new Date(asn.createdAt).toDateString(),
            createdBy: asn.supplier?.email,
          };
        });
      }
      return [];
    };

    getAsnList = async (userData: UserData, pageIndex: number, pageSize: number) => {
      const sub = userData.Username;
      const email = userData.UserAttributes.find((attr) => attr.Name === 'email')?.Value;
      const team = email?.split('@')[email?.split('@').length - 1];
      if (!sub || !email || !team) throw new Error(i18n.t('paramsNotDefined') as string);

      const res = await axios.post<ApiResponse<GetAsnList>>(this.apiKey + API_ROUTES.LIST_ASN, {
        sub,
        team,
        email,
        page: pageIndex + 1,
        limit: pageSize,
      });

      if (res.data.status === 'success') {
        this.asnList = res.data.data?.docs ?? null;
        this.asnListPageCount = res.data.data?.pages ?? 0;
        this.error = null;
        return res.data.data?.docs ?? null;
      } else if (res.data.status === 'error' || res.data.status === 'fail') {
        this.error = res.data.data;
        throw new Error(res.data.message || (i18n.t('somethingWentWrong') as string));
      } else {
        this.error = i18n.t('unhandledScenario') as string;
        throw new Error(i18n.t('unhandledScenario') as string);
      }
    };

    public generateNewAsn = async (userData: UserData) => {
      const sub = userData.Username;
      const email = userData.UserAttributes.find((attr) => attr.Name === 'email')?.Value;
      const team = email?.split('@')[email?.split('@').length - 1];
      if (!sub || !email || !team) throw new Error(i18n.t('paramsNotDefined') as string);

      const res = await axios.post<ApiResponse<Asn>>(this.apiKey + API_ROUTES.GENERATE_NEW_ASN, {
        sub,
        team,
        email,
      });

      if (res.data.status === 'success') {
        this.error = null;
        return res.data.data;
      } else if (res.data.status === 'error' || res.data.status === 'fail') {
        this.error = res.data.data;
        throw new Error(res.data.message || (i18n.t('somethingWentWrong') as string));
      } else {
        this.error = i18n.t('unhandledScenario') as string;
        throw new Error(i18n.t('unhandledScenario') as string);
      }
    };
  },
);

export type GlobalStore = ReturnType<typeof useAsnStore>;
