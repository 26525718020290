/* eslint-disable */

import { makeAutoObservable } from 'mobx';
import createStoreContext from '../createStoreContext';

export const [FullLoaderStoreProvider, useFullLoaderStore] = createStoreContext(
  class FullLoaderStore {
    loading = false;

    public constructor() {
      // NOTE: don't add anything else here  unless you know what you are doing
      // as the constructor will be executed twice due to `StrictMode`
      makeAutoObservable(this);
    }

    showFullLoader() {
      this.loading = true;
    }

    hideFullLoader() {
      this.loading = false;
    }
  },
);

export type GlobalStore = ReturnType<typeof useFullLoaderStore>;
