export const GENERAL = {
  TOAST_DURATION: 3000, // ms
  TOAST_DURATION_MEDIUM: 6000, // ms
  TOAST_DURATION_LONG: 9000, // ms
  GOPOWER_LANDING_PAGE: 'https://www.gopower.live/',
  CUSTOMER_SUPPORT_EMAIL: 'rainm@gopower.live',
  PRIVACY_POLICY: 'https://www.termsfeed.com/live/4a1bde2d-0d81-42ab-8d34-66305a616d1b',
  TERMS_AND_CONDITIONS: 'https://www.termsfeed.com/live/4a1bde2d-0d81-42ab-8d34-66305a616d1b',
  HANABI_LANDING: 'https://www.hanabitech.com/',
};
